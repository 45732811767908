<div style="background-color: #fffafa;">
<Header />

<div class="main">
	<div class="nav">
		<p :class="{active: page == 'person', selected: navPerson.selected}" @click="changePerson">{{navPerson.selected ? navPerson.person.name : '医師を選択'}}</p>
		<p :class="{active: page == 'date', selected: navDate.selected}" @click="changeDate">{{navDate.selected ? toText(navDate.text) : '日付を選択'}}</p>
		<p :class="{active: page == 'time', selected: navTime.selected}" @click="changeTime">{{navTime.selected ? navTime.text : '時間を選択'}}</p>
	</div><!--nav-->

	<div class="person" v-show="page == 'person'">
		<h3>最近チェックした医師</h3>
		<ul>
			<li v-for="(l, i) in recentlies">
				<Person :nextNav="nextNav" :d="l" />
			</li>
		</ul>

		<h3>検索する</h3>
		<div class="search">
			<input type="text" v-model="word" placeholder="氏名で検索する">
			<a class="btn" @click="search">検索</a>
		</div><!--search-->

		<h3 v-show="isSearched">検索結果</h3>
		<ul v-show="isSearched">
			<li v-for="(l, i) in searched">
				<Person :nextNav="nextNav" :d="l" />
			</li>
		</ul>
	</div><!--person-->


	<div v-show="page == 'date'">
		<Calendar :nextNav="nextNav" />
	</div><!--date-->


	<div v-show="page == 'time'">
		<Time :nextNav="nextNav" />
	</div><!--time-->

</div><!--main-->

<div class="modal confirm" v-show="isModalConfirm">
	<div class="wrp">
		<p class="modalHeader">予約内容の確認</p>
		<div class="contents">
			<p>以下の内容で予約を受け付けます。</p>
			<h3>医師</h3>
			<p>{{navPerson.person.name}}</p>
			<h3>日付</h3>
			<p>{{toText(navDate.text)}}</p>
			<h3>時間</h3>
			<p>{{navTime.text}}</p>
		</div>
	</div><!--wrp-->

	<div class="btns">
		<a class="btn sub" @click="isModalConfirm = false">修正する</a>
		<a class="btn" @click="reserve">予約する</a>
	</div><!--btns-->
</div>

<div class="menu">
	<Menu />
</div><!--menu-->

</div>
