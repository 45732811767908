import Peer from 'skyway-js'
import QRCode from 'qrcode'

export default {
	data: function(){
		return {
			peer: null,
			peerId: '',
			peerIdTo: '',
			localStream: null,
			qrText: '',
		}
	},

	mounted: async function(){
		if(this.$route.params.peer_id){
			this.peerIdTo = this.$route.params.peer_id
		}

		navigator.mediaDevices.getUserMedia({video: true, audio: true})
			.then( stream => {
				// 成功時にvideo要素にカメラ映像をセットし、再生
				const videoElm = this.$refs.myv
				videoElm.srcObject = stream
				videoElm.play()
				// 着信時に相手にカメラ映像を返せるように、グローバル変数に保存しておく
				this.localStream = stream
			}).catch( error => {
				// 失敗時にはエラーログを出力
				console.error('mediaDevice.getUserMedia() error:', error)
				return
			})

		this.peer = new Peer({
			key: 'fb56afa8-1d0d-4ff8-b30b-b9546f6a8d6e',
			debug: 3,
		})
		this.peer.on('open', () => {
			this.peerId = this.peer.id
			let url = window.location.protocol + '//' + window.location.host + '/#/rtc/' + this.peerId
			QRCode.toCanvas(this.$refs.qrCan, url, (err) => {
				if(err) console.error(err)
			})
		})
		this.peer.on('call', (mediaConnection) => {
			mediaConnection.answer(this.localStream)
			this.setEventListener(mediaConnection)
		})
	},

	methods: {
		startCall: function(){
			const mediaConnection = this.peer.call(this.peerIdTo, this.localStream)
			this.setEventListener(mediaConnection)
		},

		setEventListener: function(mediaConnection){
			mediaConnection.on('stream', (stream) => {
				// video要素にカメラ映像をセットして再生
				const videoElm = this.$refs.yov
				videoElm.srcObject = stream
				videoElm.play()
			})
		},

	},

	components: {
	},
}
