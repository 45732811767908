<template>
<div>

<h3>午前</h3>
<ul>
	<li v-for="(l, i) in am" @click="selected(l)">{{l}}</li>
</ul>

<h3>午後</h3>
<ul>
	<li v-for="(l, i) in pm" @click="selected(l)">{{l}}</li>
</ul>

</div>
</template>

<style scoped>
h3{
	margin-top: 20px;
	font-size: 14px;
}
li{
	padding: 20px;
	margin: 20px 10px;
	width: 180px;
	float: left;
	text-align: center;
	border: 1px solid #ccc;
	color: #0000cc;
	cursor: pointer;
}
li:hover{
	color: white; 
	background-color: #0000cc;
}

@media screen and (max-width: 768px){
h3{
	border-bottom: 1px solid #ccc;
}
li{
	padding: 20px;
	margin: 10px;
	width: 140px;
	border-radius: 10px;
	background-color: #f0f0f0;
	border: none;
}
}
</style>

<script>
export default {
props: {
	nextNav: {type: Function, default: () => {console.log('aaa')}},
},

data: function(){
	return {
		am: ['10:00', '10:30', '11:00'],
		pm: ['13:00', '13:15', '14:00', '15:00', '15:15', '15:30'],
	}
},

mounted: function(){
},

methods: {
	selected: function(l){
		this.nextNav('time', l)
	},
},
}
</script>
