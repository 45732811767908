import Header from '../../components/Header.vue'
import Menu from '../../components/Menu.vue'
import Item from './Item.vue'

export default {
	props: {
		msg: {type: String, default: ''},
	},

	data: function(){
		return {
			message: '',
			mention: '',
			list: [],
		}
	},

	mounted: async function(){
		if(this.$route.name == 'Done'){
			this.message = '予約しました'
			setTimeout( () => {
				this.message = ''
				this.$router.push({name: 'Home'})
			}, 2500)
		}

		this.list = [
			{id: 1, name: '山田　太郎医師', date: '2022年9月23日', time: '15時15分', status: 0},
			{id: 2, name: '山田　太郎医師', date: '2022年9月23日', time: '15時15分', status: 0},
			{id: 3, name: '山田　太郎医師', date: '2022年9月23日', time: '15時15分', status: 0},
		]

		this.mention = '明日の予約があります。ご確認ください。'
		setTimeout( () => { this.mention = '' }, 3600)
	},

	methods: {
		cancelCb: async function(){
			this.message = '予約をキャンセルしました'
			setTimeout( () => { this.message = '' }, 2500)
			this.list = [
				{id: 1, name: '山田　太郎医師', date: '2022年9月23日', time: '15時15分', status: 1},
				{id: 2, name: '山田　太郎医師', date: '2022年9月23日', time: '15時15分', status: 0},
				{id: 3, name: '山田　太郎医師', date: '2022年9月23日', time: '15時15分', status: 0},
			]
		},
	},

	components: {
		Item,
		Menu,
		Header,
	},

}
