<template>
<div>
<div class="line" :class="{canceled: d.status == 1}" @click="isModal = true">
	<p class="name">{{d.name}}</p>
	<p class="date">{{d.date}}</p>
	<p class="time">{{d.time}}</p>
</div>

<div class="modal cancel" v-show="isModal">
	<div class="wrp">
		<p class="modalHeader">予約内容の確認</p>
		<div class="contents">
			<h3>医師</h3>
			<p>{{d.name}}</p>
			<h3>日付</h3>
			<p>{{d.date}}</p>
			<h3>時間</h3>
			<p>{{d.time}}</p>
			<canvas class="qr" ref="can"></canvas>
		</div><!--contents-->
	</div><!--wrp-->

	<div class="btns">
		<a class="btn sub" @click="isModal = false">戻る</a>
		<a class="btn" @click="cancel">キャンセルする</a>
	</div><!--btns-->
</div><!--modal cancel-->

</div>
</template>

<style scoped>
.line{
	margin: 0 auto;
	padding: 25px 0;
	border-bottom: 1px solid #ccc;
}
.canceled{
	background-color: #eee;
}
p{
	display: inline-block;
}
p.name{
	width: 240px;
	margin-left: 20px;
}
p.date{
	width: 180px;
}
p.time{
	width: 180px;
}
.qr{
	display: block;
	max-width: 200px;
	margin: 20px auto;
}

.btn.cancelBtn{
	position: absolute;
	top: 0;
	bottom: 0;
	right: 20px;
	font-size: 10px;
	line-height: 22px;
	height: 24px;
	width: 100px;
	padding: 0;
	margin: auto;
}

.modal{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.8);
	z-index: 100;
}
.modal .wrp{
	position: absolute;
	width: calc(100% - 40px);
	max-width: 1024px;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	height: calc(100% - 40px);
	background-color: white;
	overflow-y: auto;
	padding-bottom: 90px;
}
.modal .modalHeader{
	width: 100%;
	height: 60px;
	text-align: center;
	color: white;
	background-color: var(--col-main);
	font-weight: bold;
	line-height: 60px;
}
.modal .contents{
	padding: 20px;
}
.modal .btns{
	position: fixed;
	bottom: 20px;
	left: 0;
	right: 0;
	height: 90px;
	text-align: center;
}
.modal .btns .btn{
	display: inline-block;
	min-width: 120px;
	margin: 20px;
}

@media screen and (max-width: 768px){
.btn.cancelBtn{
	position: absolute;
	line-height: 40px;
	height: 40px;
}

p{
	display: block;
}
p.name{
	width: auto;
	margin-left: 0;
}
p.date{
	width: auto;
}
p.time{
	width: auto;
}
.qr{
}

}

</style>

<script>
import QRCode from 'qrcode'

export default {
props: {
	d: {type: Object, default: () => { return {} } },
	cb: {type: Function, default: () => {console.log('cancel callback')} },
},
data: function(){
	return {
		isModal: false,
	}
},

mounted: function(){
	this.qrCode()
},

methods: {
	cancel: async function(){
		this.isModal = false
		this.cb()
	},

	qrCode: function(){
		QRCode.toCanvas(this.$refs.can, this.d.name, (err) => {
			if(err) console.error(err)
		})
	},

},

}
</script>

